<template>

    <div class="page__content">


        <!-- HEADER -->
                <div class="header">
                    <!-- <form class="header__search">
                        <input class="header__input" type="text" placeholder="Search"/>
                        <button class="header__start">
                            <svg class="icon icon-search">
                                <use xlink:href="img/sprite.svg#icon-search"></use>
                            </svg>
                        </button>
                    </form> -->
                    <router-link class="header__logo" :to="{name: 'portfolio'}">
                        <img src="@/assets/img/logo_main.png" alt="" style="width: 25px;"/>
                    </router-link>
                    <!-- <div class="header__group">
                        <div class="header__item ">
                            <button class="header__head">
                                <svg class="icon icon-notification">
                                    <use xlink:href="img/sprite.svg#icon-notification"></use>
                                </svg>
                            </button>
                           
                        </div>
                        
                        
                    </div> -->
                    <!-- <a class="header__user" href="sign-in.html" >
                        <img src="./img/coins/btc.svg" alt=""/>
                    </a> -->
                    <button class="header__toggle" @click="openSide()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                            <path d="M22 12H3" stroke="#ffffff"></path>
                            <g stroke="#ffffff">
                                <path d="M22 4H13"></path>
                                <path opacity=".301" d="M22 20H13"></path>
                            </g>
                            <path d="M7 7l-5 5 5 5" stroke="#ffffff"></path>
                        </svg>
                    </button>
                </div>
    
                <!-- BANNER -->
                <div class="page__row mtop-banner">

                    <div class="page__col soox">

                        <div class="widgets__bo wallets__wrapper swboxx1">
                            <div class="widgets__top " style="padding-left: 0px; padding-right: 0px;" >

                                <div class="widgets__company " @click="openBox1()">
                                    <div class="widgets__logo">
                                        <img :src="c1image" alt="">
                                    </div>

                                    <div class="widgets__details" style="cursor: pointer;">
                                           <div class="details__category">
                                                From
                                                
                                            </div>
                                            <div class="widgets__category" style="font-family: 'Neue Machina'; position: relative;">{{ c1symbol }}  <div class="exchange__arrow" style="left: 60px; position: absolute;">
                                                        <svg class="icon icon-arrow-down">
                                                    <use xlink:href="@/assets/img/sprite.svg#icon-arrow-down"></use>
                                                </svg>
                                                    </div> 
                                            </div>
                                            <div class="widgets__info " style="font-family: 'Neue Machina'; font-size: 12px;"> <span style="color: #4fbf67;">$ {{ c1balanceUsd }}</span></div>
                                    </div>
                                </div>

                                <div class="widgets__group">
                                                
                                    <div class="operations__field field js-field">
                                        
                                        <div class="field__wrap" style="position: relative;">
                                            <input class="field__input js-field-input sw-input" type="number" placeholder="$0.00" style="font-size: 20px; text-align: end; font-family: 'Neue Machina'; padding-top: 0; padding-bottom: 0; padding-left: 20px; padding-right: 10px; margin: 0; " v-model="swapAmountUsd1" @input="swapT1()">
                                            <div class="details__category" style="position: absolute; top: 60px; right: 10px;">{{ swapAmount1 }} {{ c1symbol }}</div>
                                        </div>
                                        
                                    </div>
                                                
                                </div>
                            </div>
                                        
                        </div>

                        <div class="widgets__bo wallets__wrapper swboxx">
                            <div class="widgets__top " style="padding-left: 0px; padding-right: 0px;" >
                                <div class="widgets__company" @click="openBox2()">
                                    <div class="widgets__logo">
                                        <img :src="c2image" alt="">
                                    </div>

                                    <div class="widgets__details" style="cursor: pointer;">
                                           <div class="details__category">
                                                To
                                                
                                            </div>
                                            <div class="widgets__category" style="font-family: 'Neue Machina'; position: relative;">{{ c2symbol }}  <div class="exchange__arrow" style="left: 60px; position: absolute;">
                                                        <svg class="icon icon-arrow-down" >
                                                    <use xlink:href="@/assets/img/sprite.svg#icon-arrow-down"></use>
                                                </svg>
                                                    </div> 
                                            </div>
                                            <div class="widgets__info " style="font-family: 'Neue Machina'; font-size: 12px;"> <span style="color: #4fbf67;">$ {{ c2balanceUsd }}</span></div>
                                    </div>
                                </div>

                                <div class="widgets__group" >
                                                
                                    <div class="operations__field field js-field">
                                        
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input sw-input" disabled type="text" placeholder="$0.00" v-model="swapAmountUsd1" style="font-size: 20px; text-align: end; font-family: 'Neue Machina'; padding-top: 0; padding-bottom: 0; padding-left: 20px; padding-right: 10px; margin: 0; position: relative; background-color: #070707;" >
                                            <div class="details__category" style="position: absolute; top: 60px; right: 10px;">{{ swapAmount2 }} {{ c2symbol }}</div>
                                        </div>
                                    </div>
                                                
                                </div>
                            </div>
                                        
                        </div>

                        <p class="operations__text">Please note that due to exchange rate fluctuations, there may be a slight difference between the amount you receive and the estimated amount.</p>
                        <div class="balances__btns" style="margin-top: 0px;">
                            <button class="balances__btn btn btn_blue" @click="swap()" style="width: 100%; margin-bottom: 50px; font-size: 18px; padding-top: 20px; padding-bottom: 20px; height: 60px; margin-bottom: 100px;">Swap</button>
                        </div>

                        

                        
                    </div>

                    <!-- RELATED -->
                    <div class="page__col mm-none" style="margin-top: 30px; ">
                        <div class="card " style="width: 100%;">
                            <div class="card__head card__head_mb32" style="width: 100%; margin-left: -30px;">
                                <div class="card__title h6" style="font-weight: 900;">{{c1symbol}}/{{c2symbol}}</div>  
                            </div>

                            <div class="card__head card__head_mb32" style="width: 100%; margin-left: -30px; margin-top: -20px;">
                                <div class="card__title h3">$ {{chartPriceD}}</div>  
                                <div class="status positive" v-if="chartChange > 0"> +{{chartChangeD}}% </div>
                                <div class="status negative" v-else> {{chartChangeD}}% </div>
                            </div>

                            <div class="widgets__chart" style="width: 150%; margin-left: -75px;">
                                <div id="spark-6" style="width: 150%;"></div>
                            </div>

                            <!-- <div class="widgets">
                                <div class="widgets__wrap ">
                                    <div class="widgets__box wallets__wrapper" style="padding-bottom: 180px;">
                                        <div class="widgets__top">
                                            <div class="widgets__company">
                                                <div class="widgets__logo">
                                                    <img src="@/assets/img/coins/eth.svg" alt="">
                                                </div>
                                                <div class="widgets__details">
                                                    <div class="widgets__category">Ethereum</div>
                                                    <div class="widgets__info">ETH</div>
                                                </div>
                                            </div>
                                            <div class="widgets__group">
                                                <div class="status positive" v-if="ethChange > 0"> +{{ ethChangeD }}% </div>
                                                <div class="status negative" v-else> {{ ethChangeD }}%</div>
                                                <div class="widgets__price h5">{{ ethPrice }}</div>
                                            </div>
                                        </div>
                                        <div class="widgets__chart" >
                                            <div id="spark-1" style="margin-top: -50px;"></div>
                                        </div>
                                        <button class="widgets__favorite">
                                            <svg class="icon icon-star">
                                                <use xlink:href="@/assets/img/sprite.svg#icon-star"></use>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="widgets__box wallets__wrapper" style="padding-bottom: 180px;">
                                        <div class="widgets__top">
                                            <div class="widgets__company">
                                                <div class="widgets__logo">
                                                    <img src="@/assets/img/coins/xrp.svg" alt="">
                                                </div>
                                                <div class="widgets__details">
                                                    <div class="widgets__category">Ripple</div>
                                                    <div class="widgets__info">XRP</div>
                                                </div>
                                            </div>
                                            <div class="widgets__group">
                                                <div class="status positive" v-if="xrpChange > 0"> +{{ xrpChangeD }}% </div>
                                                <div class="status negative" v-else> {{ xrpChangeD }}%</div>
                                                <div class="widgets__price h5">{{ xrpPrice }}</div>
                                            </div>
                                            
                                        </div>
                                        <div class="widgets__chart">
                                            <div id="spark-5" style="margin-top: -50px;"></div>
                                        </div>
                                        <button class="widgets__favorite">
                                            <svg class="icon icon-star">
                                                <use xlink:href="@/assets/img/sprite.svg#icon-star"></use>
                                            </svg>
                                        </button>
                                    </div>
                                    
                                    <div class="widgets__box wallets__wrapper" style="padding-bottom: 180px;">
                                        <div class="widgets__top">
                                            <div class="widgets__company">
                                                <div class="widgets__logo">
                                                    <img src="@/assets/img/coins/matic.svg" alt="">
                                                </div>
                                                <div class="widgets__details">
                                                    <div class="widgets__category">Polygon</div>
                                                    <div class="widgets__info">MATIC</div>
                                                </div>
                                            </div>
                                            <div class="widgets__group">
                                                <div class="status positive" v-if="maticChange > 0"> +{{ maticChangeD }}% </div>
                                                <div class="status negative" v-else> {{ maticChangeD }}%</div>
                                                <div class="widgets__price h5">{{ maticPrice }}</div>
                                            </div>
                                        </div>
                                        <div class="widgets__chart">
                                            <div id="spark-6"></div>
                                        </div>
                                        <button class="widgets__favorite">
                                            <svg class="icon icon-star">
                                                <use xlink:href="@/assets/img/sprite.svg#icon-star"></use>
                                            </svg>
                                        </button>
                                    </div>
                                    
                                </div>
                            </div> -->
                        </div>
                    </div>

                    
                    
                </div>

                <div class="page__row traT" style="width: 100%;">

                    <div class="page__co" style="width: 100%;">

                        <!-- TRANS -->
                        <div class="wallets__wrapper mtop-table" style="background-color: #070707; width: 100%;" id="yesT">
                            
                            <div class="balances">
                            <div class="balances__title h6" style="text-align: center; ">Swap history </div>
                            <div class="operations__field field js-field">
                                <!-- <div class="field__label">Search asset name...</div> -->
                                <div class="field__wrap" style="margin-top: 10px; padding-bottom: 20px;">
                                    <input class="field__input js-field-input" placeholder="Search asset symbol, status or amount..."  type="text" style="height: 50px; padding-bottom: 18px; font-family: 'Neue Machina';" v-model="search3" >
                                </div>
                            </div>
                            <div class="balances__table" >
                                <div class="balances__row balances__row_head">
                                   
                                    <div class="balances__cell" >Assets</div>
                                
                                    <div class="balances__cell">AMOUNT</div>

                                    <div class="balances__cell">SENT</div>

                                    <div class="balances__cell">RECEIVED</div>
                                    
                                    <div class="balances__cell">DATE</div>
                                    
                                    <div class="balances__cell">STATUS</div>
                                </div>

                                <div class="balances__row" v-for="trans in filteredtTransList" :key="trans.id">
                                    
                                    <div class="balances__cell">
                                        <div class="balances__company">
                                            <div style="display: flex; justify-content: center; align-items: center;">
                                                <div class="balances__logo">
                                                    <img :src="trans.sentImage">
                                                </div>
                                                <div class="balances__logo" style="margin-left: -20px;">
                                                    <img :src="trans.receivedImage">
                                                </div>
                                            </div>
                                            <div class="balances__text">{{ trans.sentSymbol }} to {{ trans.receivedSymbol }}</div>
                                           
                                        </div>
                                    </div>
                                    
                                    
                                    <div class="balances__cell">
                                        <div class="balances__number">$ {{ trans.amountUsd }}</div>
                                        
                                    </div>

                                    <div class="balances__cell">
                                        <div class="balances__number" style="color: #fff;">{{ trans.sent }} {{ trans.sentSymbol }}</div>
                                        
                                    </div>

                                    <div class="balances__cell">
                                        <div class="balances__number">{{ trans.received }} {{ trans.receivedSymbol }}</div>
                                        
                                    </div>

                                    <div class="balances__cell">
                                        <div class="balances__number">{{ trans.date }}</div>
                                        
                                    </div>
                                    
                                    

                                    <div class="balances__cell">
                                        <div class="balances__number" style="color: #4fbf67;">Successful</div>
                                    </div>

                                    
                                </div>

                                
                            </div>
                            
                        </div>

                            
                        </div>

                    </div>

                </div>

                <!-- BOX1 -->
                <div style=" display: flex; justify-content: center; align-items: center; z-index: 99; " class="loadingModalBg" ref="box1Modal">

                    

                    <div class="popup popup_settings " style="width: 400px; padding: 30px; border-radius: 20px;">
            <div class="popup__title h6" style="text-align: center;">Asset to swap from</div>
            <button class="mfp-close" style="position: absolute; top: 5px; right: 5px;" @click="closeBox1()">x</button>

            <div class="operations__field field js-field">
                                        
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="text" placeholder="Search asset name..." style="margin: 0; padding-top: 0px;  height: 60px;" v-model="search1">
                                        </div>
                                    </div>

            <div class="operations__for" style="max-height: 500px; overflow-y: scroll;">
                                    
                                    

                                    

                                    <div >

                                        <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 10px; padding-right: 10px; width: 100%; cursor: pointer; margin-top: 30px;" v-for="coin1 in filteredCoinList1" :key="coin1.symbol" @click="updateBox1(coin1)" >

                                            <div style="display: flex; justify-content: center; align-items: center;">
                                                <img :src="coin1.image" alt="" style="width: 40px;">
                                                <h3 style="color: #fff; margin-left: 5px;">{{ coin1.name }}</h3>
                                            </div>

                                            <div style="display: flex; justify-content: center; align-items: center;">
                                                
                                                <h3 style="color: #fff; margin-left: 5px;">$ {{ coin1.balanceUsdD }}</h3>
                                            </div>

                                        </div>                                      

                                        

                                    </div>

                                   

                                    
            </div>


                    </div>

                </div>

                
                <!-- BOX2 -->
                <div style=" display: flex; justify-content: center; align-items: center; z-index: 99; " class="loadingModalBg" ref="box2Modal">

                    

                    <div class="popup popup_settings " style="width: 400px; padding: 30px; border-radius: 20px;">
            <div class="popup__title h6" style="text-align: center;">Asset to swap to</div>
            <button class="mfp-close" style="position: absolute; top: 5px; right: 5px;" @click="closeBox2()">x</button>

            <div class="operations__field field js-field">
                                        
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="text" placeholder="Search asset name..." style="margin: 0; padding-top: 0px;  height: 60px;" v-model="search2">
                                        </div>
                                    </div>

            <div class="operations__for" style="max-height: 500px; overflow-y: scroll;">
                                    
                                    

                                    

                                    <div >

                                        <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 10px; padding-right: 10px; width: 100%; cursor: pointer; margin-top: 30px;" v-for="coin2 in filteredCoinList2" :key="coin2.symbol" @click="updateBox2(coin2)"  >

                                            <div style="display: flex; justify-content: center; align-items: center;">
                                                <img :src="coin2.image" alt="" style="width: 40px;">
                                                <h3 style="color: #fff; margin-left: 5px;">{{ coin2.name }}</h3>
                                            </div>

                                            <div style="display: flex; justify-content: center; align-items: center;">
                                                
                                                <h3 style="color: #fff; margin-left: 5px;">$ {{ coin2.balanceUsdD }}</h3>
                                            </div>

                                        </div>                                      

                                        

                                    </div>

                                   

                                    
            </div>


                    </div>

                </div>


    <!-- MODALS -->

    <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg" ref="loadingModal" >

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0; ">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px;">Please wait</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ loadingText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.gif" alt="" style="width: 50px; margin-top: -10px; margin-bottom: -20px;">

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg " ref="errorModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/error.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #EA4497;">Error!</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ errorText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 10px;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px;" @click="closeE">Okay</button>

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg " ref="successModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Success</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ successText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="closeS">Okay</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>


    </div>

</template>


<script>

    import { doc, addDoc, collection, query, where, getDocs, serverTimestamp, orderBy, updateDoc, increment } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {

        data(){
            return{

                successText: "",
                errorText: "",
                loadingText: "",

                coinList: [],
                sl:[],

                marketPrice:[],
                marketPriceDisplay:[],
                marketCap: [],
                marketChange: [],
                marketChangeD: [],
                marketVolumeD: [],
                marketSupplyD: [],

                search1: "",
                search2: "",
                search3: "",

                chartPrice:0,
                chartPriceD: "",
                chartChange: 0,
                chartChangeD:"",

                swapAmount1: 0,
                swapAmountUsd1: "",

                swapAmount2: 0,
                swapAmountUsd2: "",

                c1balanceUsd: "",
                c1balance:"",
                c1price: "",
                c1image: "",
                c1name: "",
                c1symbol: "",
                c1walletId: "",
                c1color: "",
                c1change: "",

                c2balanceUsd: "",
                c2balance:"",
                c2price: "",
                c2image: "",
                c2name: "",
                c2symbol: "",
                c2walletId: "",
                c2color:"",
                c2change: "",

                transList: [],

            }
        },

        computed:{

            filteredCoinList1(){
                return this.coinList.filter( hist => hist.name.toLowerCase().startsWith(this.search1.toLowerCase()) );
            },

            filteredCoinList2(){
                return this.coinList.filter( hist => hist.name.toLowerCase().startsWith(this.search2.toLowerCase()) );
            },

            filteredtTransList(){
                return this.transList.filter( hist => hist.amountUsd.toLowerCase().startsWith(this.search3.toLowerCase()) || hist.receivedSymbol.toLowerCase().startsWith(this.search3.toLowerCase()) || hist.sentSymbol.toLowerCase().startsWith(this.search3.toLowerCase()));
            },

        },

        mounted(){
            document.getElementById("portfolio").classList.remove("active");
            document.getElementById("swap").classList.add("active");
            document.getElementById("staking").classList.remove("active");
            //document.getElementById("markets").classList.remove("active");
            document.getElementById("trans").classList.remove("active");

            if(!(sessionStorage.getItem("id"))){
                window.location.replace("https://account.navox.io/sign-in");
            }

            this.mPop();

            this.getCoinSymbols();
            
        },

        methods:{

            mPop(){

                $('.js-popup-open').magnificPopup({
                    type: 'inline',
                    fixedContentPos: true,
                    removalDelay: 200,
                    callbacks: {
                        beforeOpen: function beforeOpen() {
                            this.st.mainClass = this.st.el.attr('data-effect');
                        }
                    }
                });
            },

            openBox1(){
                this.$refs.box1Modal.classList.add("bg-active");
            },

            openBox2(){
                this.$refs.box2Modal.classList.add("bg-active");
            },

            closeBox1(){
                this.$refs.box1Modal.classList.remove("bg-active");
            },

            closeBox2(){
                this.$refs.box2Modal.classList.remove("bg-active");
            },

            updateBox1(coin){

                this.c1name = coin.name;
                this.c1symbol = coin.symbol;
                this.c1image = coin.image;
                this.c1balance = coin.balance;
                this.c1balanceUsd = coin.balanceUsd;
                this.c1price = coin.price;
                this.c1walletId = coin.walletId;
                this.c1color = coin.color;
                this.c1change = coin.change;

                this.swapT1();
                this.getChartData();

                this.$refs.box1Modal.classList.remove("bg-active");

            },

            updateBox2(coin){

                this.c2name = coin.name;
                this.c2symbol = coin.symbol;
                this.c2image = coin.image;
                this.c2balance = coin.balance;
                this.c2balanceUsd = coin.balanceUsd;
                this.c2price = coin.price;
                this.c2walletId = coin.walletId;
                this.c2color = coin.color;
                this.c2change = coin.change;

                this.swapT1();
                this.getChartData();

                this.$refs.box2Modal.classList.remove("bg-active");

            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");  
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            

            openSide(){

                document.getElementById("sidebar").classList.add("active")

            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            getChartData(){


                // axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+this.c1symbol+'&tsyms='+this.c2symbol)
                // .then( response => {

                //     this.chartPrice = Number(Object.values(response.data.RAW)[0].Object.values(Object.keys(response.data.RAW)[0])[0].PRICE)
                //     this.chartChangeD = Object.values(response.data.DISPLAY)[0].c1symbol.PRICE;
                        
                //     this.chartChange = Number(Object.values(response.data.RAW)[0].USD.CHANGEPCT24HOUR);
                //     this.chartChangeD = Number(Object.values(response.data.DISPLAY)[0].USD.CHANGEPCT24HOUR);
                        
                // }); 

                this.chartPrice = this.c1price/this.c2price;
                this.chartPriceD = Number(this.chartPrice).toLocaleString("us-Us");
                
                this.chartChange = this.c1change - this.c2change;
                this.chartChangeD = this.round(this.chartChange, 2);
                


                let chartArray = [];
                let options = {
                        series: [{
                            data: chartArray

                        }],

                        tooltip: {
                            y: {
                                title: {
                                formatter: function (seriesName) {
                                    return "$";
                                },
                                },
                            },

                            x: {
                                format: "dd MMM yyyy",
                            },
                            },

                            stroke: {
                                width: 2,
                            
                            },

                            fill: {
                                type: "pattern",
                                pattern: {
                                    style: 'verticalLines',
                                    width: 1,
                                    height: 4,
                                    strokeWidth: 0,
                                },
                            },

                        chart: {
                            id: 'area-datetime',
                            type: 'area',
                            height: 500,
                            width: '100%',
                            zoom: {
                                enabled: true
                            },
                            sparkline: {
                                enabled: false
                            },
                            toolbar: {
                                show: false
                            },
                        },
                        colors: [''+this.c1color, ''+this.c2color],
                        dataLabels: {
                            enabled: false
                        },
                        markers: {
                            size: 0,
                            style: 'hollow',
                        },
                        xaxis: {
                            show: true,
                            type: "datetime",

                            labels: {
                                format: "MMM yy",
                            },
                            axisBorder: {
                                show: true, 
                            },
                        },

                        yaxis: {
                        show: false,
                        }, 
                        grid: {
                        show: true,
                        },
                        responsive: [{
                            breakpoint: 1024,
                            options: {
                                chart: {
                                    width: '100%',
                                }

                            }
                        }],
                    };

                    let chart = new ApexCharts(document.getElementById("spark-6"),options);
                    
                    chart.render();

                try{
                            fetch('https://min-api.cryptocompare.com/data/v2/histoday?fsym='+this.c1symbol+'&tsym='+this.c2symbol+'&limit=300', {method: 'GET'}).then(response => {
                                if(!response.ok){
                                    throw new Error(`Failed to fetch posts ${response.status}`);
                                }
                                
                                return response.json();
                            })
                            .then(data => {
                                
                                for(let i = 0; i < 300; i++){
                                    chartArray[i] = [(data.Data.Data[i].time * 1000), data.Data.Data[i].close];
                                }

                                chart.updateSeries([{
                                    
                                    data: chartArray
                                }])
                                
                            })
                }
                catch(e){
                    console.log(e);
                }
            },

            async getCoinSymbols(){

               //start loading
               this.loadingText= "Loading assets...";
               this.$refs.loadingModal.classList.add("bg-active"); 

               let i = -1;
               const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc")); 
               const querySnapshot = await getDocs(q); 

               querySnapshot.forEach((doc) => {
                    i++;
                    this.sl[i] = doc.data().symbol;

                    if(this.sl[i] == "IOTA"){
                        this.sl[i] = "MIOTA"
                    }
               })

               this.getMarketData();
            },

            getMarketData(){
                
                //get coin prices and changes
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+this.sl[0]+','+this.sl[1]+','+this.sl[2]+','+this.sl[3]+','+this.sl[4]+','+this.sl[5]+','+this.sl[6]+','+this.sl[7]+','+this.sl[8]+','+this.sl[9]+','+this.sl[10]+','+this.sl[11]+','+this.sl[12]+','+this.sl[13]+','+this.sl[14]+','+this.sl[15]+','+this.sl[16]+','+this.sl[17]+','+this.sl[18]+','+this.sl[19]+','+this.sl[20]+','+this.sl[21]+','+this.sl[22]+','+this.sl[23]+','+this.sl[24]+','+this.sl[25]+','+this.sl[26]+','+this.sl[27]+','+this.sl[28]+','+this.sl[29]+','+this.sl[30]+','+this.sl[31]+','+this.sl[32]+','+this.sl[33]+','+this.sl[34]+','+this.sl[35]+','+this.sl[36]+','+this.sl[37]+','+this.sl[38]+','+this.sl[39]+','+this.sl[40]+','+this.sl[41]+','+this.sl[42]+','+this.sl[43]+','+this.sl[44] +'&tsyms=USD')
                .then( response => {

                    for(let i = 0; i < this.sl.length; i++){
                        
                        this.marketPrice[i] = Number(Object.values(response.data.RAW)[i].USD.PRICE)
                        this.marketPriceDisplay[i] = Object.values(response.data.DISPLAY)[i].USD.PRICE;
                        this.marketCap[i] = Object.values(response.data.RAW)[i].USD.MKTCAP;
                        this.marketChange[i] = Number(Object.values(response.data.RAW)[i].USD.CHANGEPCT24HOUR);
                        this.marketChangeD[i] = Number(Object.values(response.data.DISPLAY)[i].USD.CHANGEPCT24HOUR);
                        this.marketVolumeD[i] = Object.values(response.data.DISPLAY)[i].USD.VOLUME24HOURTO;
                        this.marketSupplyD[i] = Object.values(response.data.DISPLAY)[i].USD.SUPPLY;
                        
                    }

                    this.getCoins();
                }); 


            
            },

            async getCoins(){

                let i = -1;
                let a=0;
                let tempB = 0;
                let tempA = 0;
                let tempSpot = 0;
                let assetsHeld = 0;
                let balance;


                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc"));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    
                    i++;

                    //check balance not minus
                    balance = doc.data().balance;

                    if(balance < 0 || isNaN(balance)){
                        balance = 0;
                        this.minusBalFix(doc.id);
                    }

                    a = Number(balance) * Number(this.marketPrice[i]);
                    tempSpot += a;

                    if(a > 0){
                        assetsHeld++;
                    }

                    tempB = Number(balance)  * Number(this.marketPrice[i]);
                    tempA = Number(balance);

                    let ballD = Number(tempB).toLocaleString("us-US");

                    if(tempB == 0){
                        ballD = "0.00";
                    }

                    this.coinList[i] = {      

                        number: i,
                        walletId: doc.id,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        color: doc.data().color,
                        address: doc.data().address,
                        qrcode: "https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl="+doc.data().address+"&choe=UTF-8",
                        description: doc.data().description,
                        balance: this.round(tempA,6),
                        balanceD: this.round(tempA,6),
                        balanceUsd: this.round( tempB ,2),
                        balanceUsdD: ballD,
                        image: doc.data().image,
                        chartImg: doc.data().chartImg,
                        price: this.marketPrice[i],
                        priceD: this.marketPriceDisplay[i],
                        change: Number(this.marketChange[i]).toFixed(4),
                        changeD: this.marketChangeD[i],
                        marketCap: Number(this.marketCap[i]).toLocaleString("us-US"),
                        volume: this.marketVolumeD[i],
                        supply: this.marketSupplyD[i]

                    }
                    

                });

                this.c1balance = this.coinList[0].balance;
                this.c1price = this.coinList[0].price;
                this.c1balanceUsd = this.round(Number(this.coinList[0].balanceUsd), 2);
                this.c1name = this.coinList[0].name;
                this.c1symbol = this.coinList[0].symbol;
                this.c1image = this.coinList[0].image;
                this.c1walletId = this.coinList[0].walletId;
                this.c1color = this.coinList[0].color;
                this.c1change = this.coinList[0].change;

                this.c2balance = this.coinList[1].balance;
                this.c2price = this.coinList[1].price;
                this.c2balanceUsd = this.round(Number(this.coinList[1].balanceUsd), 2);
                this.c2name = this.coinList[1].name;
                this.c2symbol = this.coinList[1].symbol;
                this.c2image = this.coinList[1].image;
                this.c2walletId = this.coinList[1].walletId;
                this.c2color = this.coinList[1].color;
                this.c2change = this.coinList[1].change;


                //chart
                this.getChartData()

                this.getSwapTrans();


            },

            async minusBalFix(walletId){
                const walletRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(walletRef, {
                    balance: 0
                });
            },

            swapT1(){
               let temp =  this.swapAmountUsd1 / this.c1price;
               this.swapAmount1 = this.round(temp, 6);

               let temp2 =  this.swapAmountUsd1 / this.c2price;
               this.swapAmount2 = this.round(temp2, 6);
            },

            async swap(){

                this.loadingText= "Processing transaction...";
                this.$refs.loadingModal.classList.add("bg-active");

                if(this.swapAmountUsd1 == "" || isNaN(this.swapAmountUsd1)){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Invalid amount";
                    return;
                }

                if(Number(this.swapAmountUsd1) < 10){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "The minimum amount is $10";
                    return;
                }

                if(this.c1symbol == this.c2symbol){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Can not swap "+this.c1symbol+" for "+this.c2symbol;
                    return;
                }

                if(Number(this.c1balanceUsd) < Number(this.swapAmountUsd1)){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+this.c1symbol+" balance";
                    return;
                }

                //update coin1
                const c1Ref = doc(db, "UserWallets", String(this.c1walletId));
                await updateDoc(c1Ref, {
                    balance: increment(- Number(this.swapAmount1)),
                });

                //update coin2
                
                const c2Ref = doc(db, "UserWallets", String(this.c2walletId));
                await updateDoc(c2Ref, {
                    balance: increment(Number(this.swapAmount2)),
                });


                //upload transaction
                await addDoc(collection(db, "SwapTrans"), {
                      amountUsd: this.swapAmountUsd1,
                      status: "successful",
                      sent: this.swapAmount1,
                      sentSymbol: this.c1symbol,
                      received: this.swapAmount2,
                      receivedSymbol: this.c2symbol,
                      timeStamp: serverTimestamp(),
                      userId: sessionStorage.getItem("id"),
                      name: sessionStorage.getItem("name"),
                      email: sessionStorage.getItem("email"),
                      sentImage: this.c1image,
                      receivedImage: this.c2image,
                });

                //successful
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = "Successfully swapped from "+this.c1symbol+" to "+this.c2symbol;

            },

            async getSwapTrans(){
                let i = -1;
                const q = query(collection(db, "SwapTrans"), where("userId", "==", sessionStorage.getItem("id")), orderBy("timeStamp", "desc")); 
                const querySnapshot = await getDocs(q);
                
                if(querySnapshot.docs.length > 0){
                    let i = -1;
                    let a2;
                    

                    querySnapshot.forEach((doc) => {
                        i++;

                        a2 = String(new Date(Number(doc.data().timeStamp.seconds) * 1000));

                        this.transList[i] = {
                            id: doc.id,
                            amountUsd: Number(doc.data().amountUsd).toLocaleString("us-US"),
                            received: Number(doc.data().received),
                            receivedSymbol: doc.data().receivedSymbol,
                            receivedImage: doc.data().receivedImage,
                            sent: Number(doc.data().sent),
                            sentSymbol: doc.data().sentSymbol,
                            sentImage: doc.data().sentImage,
                            date : a2.slice(0, -36)
                        }

                        
                    })



                }
                else{
                    document.getElementById("yesT").style.display = "none";
                    
                    
                }

                this.$refs.loadingModal.classList.remove("bg-active");
                
            },
            

        },
        
    }

</script>