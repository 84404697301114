<template>
    
    <!-- MAIN -->
            <div class="page__content">

                <!-- HEADER -->
                <div class="header">
                    <!-- <form class="header__search">
                        <input class="header__input" type="text" placeholder="Search"/>
                        <button class="header__start">
                            <svg class="icon icon-search">
                                <use xlink:href="img/sprite.svg#icon-search"></use>
                            </svg>
                        </button>
                    </form> -->
                    <router-link class="header__logo" :to="{name: 'portfolio'}">
                        <img src="@/assets/img/logo_main.png" alt="" style="width: 25px;"/>
                    </router-link>
                    <!-- <div class="header__group">
                        <div class="header__item ">
                            <button class="header__head">
                                <svg class="icon icon-notification">
                                    <use xlink:href="img/sprite.svg#icon-notification"></use>
                                </svg>
                            </button>
                           
                        </div>
                        
                        
                    </div> -->
                    <!-- <a class="header__user" href="sign-in.html" >
                        <img src="./img/coins/btc.svg" alt=""/>
                    </a> -->
                    <button class="header__toggle" @click="openSide()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                            <path d="M22 12H3" stroke="#ffffff"></path>
                            <g stroke="#ffffff">
                                <path d="M22 4H13"></path>
                                <path opacity=".301" d="M22 20H13"></path>
                            </g>
                            <path d="M7 7l-5 5 5 5" stroke="#ffffff"></path>
                        </svg>
                    </button>
                </div>

                <!-- BANNER -->
                <div class="wallets mtop-banner" >
                    <div class="wallets__container">
                        <div class="wallets__wrapper" style="background-image: url(https://firebasestorage.googleapis.com/v0/b/bitpokket.appspot.com/o/wal_back.jpg?alt=media&token=8b8fe051-4509-445b-859a-730b2d6e5c66&_gl=1*w9pkmo*_ga*NTg4MjE0Mzk5LjE2NjU1MzI2MjM.*_ga_CW55HF8NVT*MTY5ODQzMzI4MS4xNjEuMS4xNjk4NDMzMzI2LjE1LjAuMA..); background-size: cover;">
                            <div class="wallets__row">
                                <div class="wallets__total">
                                    <div class="wallets__title wallets__info" style="margin-top: 20px; margin-bottom: 5px; margin-left: 5px;">Portfolio balance</div>
                                    <div class="wallets__flex">
                                        <div class="wallets__number h1">$ {{ totalBalanceD }}</div>
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div class="wallets__inner">
                                <div class="wallets__list mm-none" style="margin-top: -20px; margin-bottom: 20px;">
                                    
                                    <div style="display: flex; justify-content: center; align-items: flex-start; flex-direction: column; margin-left: 20px; margin-right: 20px;">
                                        <div class="wallets__info">Staked assets</div>
                                        <div class="wallets__number h6">$ {{ totalLockedUsd }}</div>
                                        
                                    </div>

                                   <div class="vl"></div>

                                    <div style="display: flex; justify-content: center; align-items: flex-start; flex-direction: column; margin-left: 20px; margin-right: 20px;">
                                        <div class="wallets__info">Pending rewards</div>
                                        <div class="wallets__number h6">$ {{ totalRewardUsd }}</div>
                                        
                                    </div>

                                    <!-- <div class="vl"></div> -->

                                    <!-- <div style="display: flex; justify-content: center; align-items: flex-start; flex-direction: column; margin-left: 20px; margin-right: 20px;">
                                        <div class="wallets__info">Assets held</div>
                                        <div class="wallets__number h6">{{ totalHeld }} / {{ totalCoins+1 }}</div>
                                        
                                    </div> -->


                                    
                                     

                                    <!-- <div class="wallets__item">
                                        
                                        <div class="wallets__details">
                                            <div class="wallets__info">Locked balance</div>
                                            <div class="wallets__number h6">0.256567545 BTC</div>
                                            <div class="wallets__price">3,700.96 USD</div>
                                        </div>
                                    </div> -->

                                    
                                </div>

                                

                                 <div class="wallets__list dd-none" style="margin-top: -20px; margin-bottom: 20px; width: 100%;">
                                    
                                    <div style="display: flex; justify-content: center; align-items: flex-start; flex-direction: column; margin-left: 10px; margin-right: 10px; width: 100%;">
                                        <div class="wallets__info">Staked </div>
                                        <div class="wallets__number h6">$ {{ totalLockedUsd }}</div>
                                        
                                    </div>

                                   

                                    <div style="display: flex; justify-content: center; align-items: end; flex-direction: column; margin-left: 10px; margin-right: 10px; width: 100%;">
                                        <div class="wallets__info">Rewards</div>
                                        <div class="wallets__number h6">$ {{ totalRewardUsd }}</div>
                                        
                                    </div>

                                    <!-- <div class="wallets__item">
                                        
                                        <div class="wallets__details">
                                            <div class="wallets__info">Locked balance</div>
                                            <div class="wallets__number h6">0.256567545 BTC</div>
                                            <div class="wallets__price">3,700.96 USD</div>
                                        </div>
                                    </div> -->

                                    
                                </div>

                                <div class="wallets__box" style="padding-top: 20px; margin-bottom: 20px;">
                                      <div class="wallets__info" >Holding {{ totalHeld }} of {{ totalCoins+1 }} available assets</div>
                                      <div class="wallets__progress" style="width: 100%; height: 6px;  background-color: #424141; margin-top: 10px;">
                                        <div class="wallets__progress bg-blue"  id="held" style="height: 5px"></div>
                                    </div>
                                  </div>
                                
                            </div>
                            
                        </div>

                        <div class="wallets__wrapper mtop-table mm-none" style="background-color: #070707; ">
                            
                            

                            <div class="balances">
                            <div class="balances__title h6" style="margin-top: 20px;"> Assets </div>
                            <div class="operations__text" style="margin-top: -25px;">Select an asset to send, receive or buy</div>
                            <div class="operations__field field js-field">
                                <!-- <div class="field__label">Search asset name...</div> -->
                                <div class="field__wrap" style="margin-top: 5px; padding-bottom: 20px;">
                                    <input class="field__input js-field-input" placeholder="Search asset name or symbol..."  type="text" style="height: 50px; padding-bottom: 18px; font-family: 'Neue Machina';" v-model="search" >
                                </div>
                            </div>
                            <div class="balances__table" >
                                <div class="balances__row balances__row_head">
                                    <div class="balances__cell">#</div>
                                    <div class="balances__cell">ASSET</div>
                                    
                                    <div class="balances__cell">24HR CHANGE</div>
                                    <div class="balances__cell">PRICE</div>
                                    <div class="balances__cell">24HR VOLUME</div>
                                    <div class="balances__cell">7 DAY Chart</div>
                                    <div class="balances__cell balTT">TOTAL BALANCE</div>
                                </div>

                                <div class="balances__row " v-for="coin in filteredCoinList" :key="coin.symbol" @click="goToNext(coin)">
                                    <div class="balances__cell">
                                        
                                        <div class="balances__price"> {{ coin.number+1 }}</div>
                                    </div>
                                    <div class="balances__cell">
                                        <div class="balances__company">
                                            <div class="balances__logo">
                                                <img :src="coin.image">
                                            </div>
                                            <div class="balances__text mm-none">{{ coin.name }} <span style="font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;"> - {{ coin.symbol }}</span></div>
                                            <div class="balances__text dd-none">{{ coin.symbol }}</div>
                                            
                                           
                                        </div>
                                    </div>

                                    

                                    <div class="balances__cell">
                                        <div class="status positive" v-if="coin.change > 0">{{ coin.changeD }} %</div>
                                        <div class="status negative" v-else>{{ coin.changeD }} %</div>
                                    </div>
                                    <div class="balances__cell">
                                        <div class="balances__number">{{ coin.priceD }}</div>
                                        <!-- <div class="balances__price"> {{ coin.marketCap }}</div> -->
                                    </div>


                                    

                                    <div class="balances__cell">
                                        <div class="balances__number">{{ coin.volume }}</div>
                                        <!-- <div class="balances__price">{{ coin.supply }}</div> -->
                                    </div>
                                    <div class="balances__cell">
                                        <img :src="coin.chartImg" alt="">
                                    </div>
                                    <div class="balances__cell">
                                        <div class="balances__number balTT" >$ {{ coin.balanceUsdD }}</div>
                                        <div class="balances__price balTT">{{ coin.balanceD }} {{ coin.symbol }}</div>
                                    </div>
                                </div>

                               

                                

                            </div>
                            <!-- <div class="balances__btns">
                                <button class="balances__btn btn btn_blue">Load more</button>
                            </div> -->
                        </div>

                            
                        </div>

                        <div class="wallets__wrappe mtop-table dd-none" style="background-color: #070707; border-radius: 24px; padding: 20px;">
                            
                            

                            <div class="balances" style="width: 100%;">
                            <div class="balances__title h6" style="margin-top: 15px;"> Assets </div>
                            <div class="operations__text" style="margin-top: -18px;">Select an asset to send, receive or buy</div>
                            <div class="operations__field field js-field">
                                <!-- <div class="field__label">Search asset name...</div> -->
                                <div class="field__wrap" style="margin-top: 5px; padding-bottom: 20px;">
                                    <input class="field__input js-field-input" placeholder="Search asset name or symbol..."  type="text" style="height: 50px; padding-bottom: 18px; font-family: 'Neue Machina';" v-model="search" >
                                </div>
                            </div>
                            <div class="balances__table" >
                                <div class="balances__row balances__row_head">
                                    <div class="balances__cell">#</div>
                                    <div class="balances__cell">ASSET</div>
                                    
                                    <div class="balances__cell">24HR CHANGE</div>
                                    <div class="balances__cell">PRICE/MARKET CAP</div>
                                    <div class="balances__cell">24HR VOLUME/SUPPLY</div>
                                    <div class="balances__cell">7 DAY Chart</div>
                                    <div class="balances__cell balTT">TOTAL BALANCE</div>
                                </div>

                                <div class="balances__row " v-for="coin in filteredCoinList" :key="coin.symbol" @click="goToNext(coin)">
                                    <div class="balances__cell">
                                        
                                        <div class="balances__price"> {{ coin.number+1 }}</div>
                                    </div>
                                    <div class="balances__cell">
                                        <div class="balances__company">
                                            <div class="balances__logo">
                                                <img :src="coin.image">
                                            </div>
                                            <div class="balances__text mm-none">{{ coin.name }} <span style="font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;"> - {{ coin.symbol }}</span></div>
                                            <div class="balances__text dd-none">{{ coin.symbol }}</div>
                                            
                                           
                                        </div>
                                    </div>

                                    

                                    <div class="balances__cell">
                                        <div class="status positive" v-if="coin.change > 0">{{ coin.changeD }} %</div>
                                        <div class="status negative" v-else>{{ coin.changeD }} %</div>
                                    </div>
                                    <div class="balances__cell">
                                        <div class="balances__number">{{ coin.priceD }}</div>
                                        <div class="balances__price"> {{ coin.marketCap }}</div>
                                    </div>


                                    

                                    <div class="balances__cell">
                                        <div class="balances__number">{{ coin.volume }}</div>
                                        <div class="balances__price">{{ coin.supply }}</div>
                                    </div>
                                    <div class="balances__cell">
                                        <img :src="coin.chartImg" alt="">
                                    </div>
                                    <div class="balances__cell">
                                        <div class="balances__number balTT" >$ {{ coin.balanceUsdD }}</div>
                                        <div class="balances__price balTT">{{ coin.balanceD }} {{ coin.symbol }}</div>
                                    </div>
                                </div>

                               

                                

                            </div>
                            <!-- <div class="balances__btns">
                                <button class="balances__btn btn btn_blue">Load more</button>
                            </div> -->
                        </div>

                            
                        </div>

                       

                    </div>


                    
                </div>

            </div>

            <!-- MODALS -->

        <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg" ref="loadingModal" >

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0; ">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px;">Please wait</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ loadingText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.gif" alt="" style="width: 50px; margin-top: -10px; margin-bottom: -20px;">

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg " ref="errorModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/error.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #EA4497;">Error!</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ errorText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 10px;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px;" @click="closeE">Okay</button>

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999;" class="loadingModalBg " ref="successModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Success</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ successText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="closeS">Okay</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

</template>

<script>

    import { doc, getDoc, addDoc, collection, query, where, getDocs, orderBy, updateDoc } from "firebase/firestore";
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {
        
        data(){
            return{
                successText: "",
                errorText: "",
                loadingText: "",

                search: "",

                totalTrans: "",
                totalSent: "",
                totalReceived: "",
                totalBalance: 0,
                totalBalanceD: "0.00",
                totalCoins: 0,
                totalHeld: 0,
                heldP: "",

                coinList: [],
                sl:[],

                marketPrice:[],
                marketPriceDisplay:[],
                marketCap: [],
                marketChange: [],
                marketChangeD: [],
                marketVolumeD: [],
                marketSupplyD: [],

                search: "",

                historyList:[],
                totalLockedUsd: "0.00",
                totalRewardUsd: "0.00",
            }
        },

        

        mounted(){

            document.body.style.overflowY = 'scroll';
            document.getElementById("portfolio").classList.add("active");
            document.getElementById("swap").classList.remove("active");
            document.getElementById("staking").classList.remove("active");
            //document.getElementById("markets").classList.remove("active");
            document.getElementById("trans").classList.remove("active");

            let urlString = String(window.location.href);
            let url = new URL(urlString);
            let userId = url.searchParams.get("id");
            
            if(userId != null){
                sessionStorage.setItem("id", userId);
            }

            if(!(sessionStorage.getItem("id"))){
                window.location.replace("https://account.navox.io/sign-in");
            }

            this.getCoinSymbols();
            this.reward();
            this.mPop();
        },

        computed:{

            filteredCoinList(){
                return this.coinList.filter( hist => hist.name.toLowerCase().startsWith(this.search.toLowerCase()) || hist.symbol.toLowerCase().startsWith(this.search.toLowerCase()) );
            },

        },

        methods:{

            openSide(){

                document.getElementById("sidebar").classList.add("active")

            },

            mPop(){

                $('.js-popup-open').magnificPopup({
                    type: 'inline',
                    fixedContentPos: true,
                    removalDelay: 200,
                    callbacks: {
                        beforeOpen: function beforeOpen() {
                            this.st.mainClass = this.st.el.attr('data-effect');
                        }
                    }
                });
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async getCoinSymbols(){

               //start loading
               this.loadingText= "Loading...";
               this.$refs.loadingModal.classList.add("bg-active"); 

               let i = -1;
               const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc")); 
               const querySnapshot = await getDocs(q); 

               querySnapshot.forEach((doc) => {
                    i++;
                    this.sl[i] = doc.data().symbol;
                    if(this.sl[i] == "IOTA"){
                        this.sl[i] = "MIOTA"
                    }
               })

               this.getMarketData();
            },

            getMarketData(){
                
                //get coin prices and changes
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+this.sl[0]+','+this.sl[1]+','+this.sl[2]+','+this.sl[3]+','+this.sl[4]+','+this.sl[5]+','+this.sl[6]+','+this.sl[7]+','+this.sl[8]+','+this.sl[9]+','+this.sl[10]+','+this.sl[11]+','+this.sl[12]+','+this.sl[13]+','+this.sl[14]+','+this.sl[15]+','+this.sl[16]+','+this.sl[17]+','+this.sl[18]+','+this.sl[19]+','+this.sl[20]+','+this.sl[21]+','+this.sl[22]+','+this.sl[23]+','+this.sl[24]+','+this.sl[25]+','+this.sl[26]+','+this.sl[27]+','+this.sl[28]+','+this.sl[29]+','+this.sl[30]+','+this.sl[31]+','+this.sl[32]+','+this.sl[33]+','+this.sl[34]+','+this.sl[35]+','+this.sl[36]+','+this.sl[37]+','+this.sl[38]+','+this.sl[39]+','+this.sl[40]+','+this.sl[41]+','+this.sl[42]+','+this.sl[43]+','+this.sl[44] +'&tsyms=USD')
                .then( response => {

                    for(let i = 0; i < this.sl.length; i++){
                        
                        this.marketPrice[i] = Number(Object.values(response.data.RAW)[i].USD.PRICE)
                        this.marketPriceDisplay[i] = Object.values(response.data.DISPLAY)[i].USD.PRICE;
                        this.marketCap[i] = Object.values(response.data.RAW)[i].USD.MKTCAP;
                        this.marketChange[i] = Number(Object.values(response.data.RAW)[i].USD.CHANGEPCT24HOUR);
                        this.marketChangeD[i] = Number(Object.values(response.data.DISPLAY)[i].USD.CHANGEPCT24HOUR);
                        this.marketVolumeD[i] = Object.values(response.data.DISPLAY)[i].USD.VOLUME24HOURTO;
                        this.marketSupplyD[i] = Object.values(response.data.DISPLAY)[i].USD.SUPPLY;
                        
                        
                    }

                    this.getCoins();
                }); 


            
            },

            async getCoins(){

                let i = -1;
                let a=0;
                let tempB = 0;
                let tempA = 0;
                let tempSpot = 0;
                let assetsHeld = 0;
                let balance;


                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc"));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    
                    i++;

                    //check balance not minus
                    balance = doc.data().balance;

                    if(balance < 0 || isNaN(balance)){
                        balance = 0;
                        this.minusBalFix(doc.id);
                    }

                    a = Number(balance) * Number(this.marketPrice[i]);
                    tempSpot += a;

                    if(a > 0){
                        assetsHeld++;
                    }

                    tempB = Number(balance)  * Number(this.marketPrice[i]);
                    tempA = Number(balance);


                    let ballD = Number(tempB).toLocaleString("us-US");

                    if(tempB == 0){
                        ballD = "0.00";
                    }

                    this.coinList[i] = {      

                        number: i,
                        walletId: doc.id,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        color: doc.data().color,
                        address: doc.data().address,
                        qrcode: "https://quickchart.io/qr?text="+doc.data().address,
                        description: doc.data().description,
                        balance: this.round(tempA,6),
                        balanceD: this.round(tempA,6),
                        balanceUsd: this.round( tempB ,2),
                        balanceUsdD: ballD,
                        image: doc.data().image,
                        chartImg: doc.data().chartImg,
                        price: this.marketPrice[i],
                        priceD: this.marketPriceDisplay[i],
                        change: Number(this.marketChange[i]).toFixed(4),
                        changeD: this.marketChangeD[i],
                        marketCap: Number(this.marketCap[i]).toLocaleString("us-US"),
                        volume: this.marketVolumeD[i],
                        supply: this.marketSupplyD[i]

                    }
                    

                });

                this.totalBalance = this.round(tempSpot, 2);
                this.totalBalanceD = tempSpot.toLocaleString("us-US");

                if(tempSpot == 0){
                    tempSpot = 0.00;
                    this.totalBalanceD = "0.00";
                }

                this.totalHeld = assetsHeld;
                this.totalCoins = i++;

                let p = (this.totalHeld/this.totalCoins) * 100;

                this.heldP = p+"%";
                document.getElementById("held").style.width = this.heldP;

                //close loader
                this.$refs.loadingModal.classList.remove("bg-active");


            },

            async minusBalFix(walletId){
                const walletRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(walletRef, {
                    balance: 0
                });
            },

            goToNext(coin){
                sessionStorage.setItem("coinName", coin.name);
                sessionStorage.setItem("coinPrice", coin.price);
                sessionStorage.setItem("coinDescription", coin.description);
                sessionStorage.setItem("coinPriceD", coin.priceD);
                sessionStorage.setItem("coinChange", coin.change);
                sessionStorage.setItem("coinChangeD", coin.changeD);
                sessionStorage.setItem("coinImage", coin.image);
                sessionStorage.setItem("coinQrcode", coin.qrcode);
                sessionStorage.setItem("coinSymbol", coin.symbol);
                sessionStorage.setItem("coinSymbol2", coin.symbol);
                sessionStorage.setItem("coinWalletId", coin.walletId);
                sessionStorage.setItem("coinColor", coin.color);
                sessionStorage.setItem("coinAddress", coin.address);
                sessionStorage.setItem("coinMarketCap", coin.marketCap);
                sessionStorage.setItem("coinVolume", coin.volume);
                sessionStorage.setItem("coinSupply", coin.supply);

                if(coin.symbol == "IOTA"){
                    sessionStorage.setItem("coinSymbol2", "MIOTA");
                }
                

                this.$router.push({name: 'asset'});


            },

            async getHistory(){

                const q = query(collection(db, "UserStakings"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 

                const querySnapshot = await getDocs(q);

                let i = -1;
                let a2, a3, days;
                let activeTemp = 0;
                let completedTemp = 0;
                

                let todaySecs = new Date().getTime()/1000;
                let mReward = 0;

                querySnapshot.forEach((doc) => {
                    i++;

                    this.totalStakes++;

                    a2 = String(new Date(Number(doc.data().startDate.seconds) * 1000));
                    a3 = String(new Date(Number(doc.data().endDate) * 1000));
                    mReward = Number(doc.data().reward);
                    
                    

                    //FS rewards
                    if(doc.data().type == "Flexible staking"){
                        let checkSecs = todaySecs - doc.data().startDate.seconds;
                        days = checkSecs/(60 * 60 * 24);

                        let rApy = doc.data().apy/100;
                        mReward = doc.data().lockedAmount * (rApy/365) * Number(days);
                    }

                    this.historyList[i] = {
                        number: i,
                        id: doc.id,
                        apy: Number(doc.data().apy),
                        endDateSecs: Number(doc.data().endDate),
                        endDate: a3.slice(4, -36),
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        lockedAmount: Number(doc.data().lockedAmount),
                        lockedPeriod: Number(doc.data().lockedPeriod),
                        activePeriod: this.round(days, 0),
                        name: doc.data().name,
                        reward: this.round(mReward, 7),
                        startDate: a2.slice(4, -36),
                        startDateSecs: Number(doc.data().startDate.seconds),
                        status: doc.data().status,
                        type: doc.data().type,
                        userId: doc.data().userId,
                        walletId: doc.data().walletId,
                    }
                    
                })

                if(this.historyList.length > 0){
                    this.getTotal();
                } 

            },

            async getTotal(){

                let temp;
                let temp2 = 0;
    
                let temp3;
                let temp4 = 0;

                let totalUsd;
                let totalLockedUsd;

                let num = 0;
                let totalApy = 0

                for(let i=0; i< this.historyList.length; i++){

                    num++
                   
                    if(this.historyList[i].status == 'in progress'){

                        let tempSym;
                        tempSym = this.historyList[i].symbol;

                        if(tempSym == "IOTA"){
                            tempSym = "MIOTA";
                        }

                        await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+tempSym+'&tsyms=USD')
                        .then( response => {

                            let price = Number(Object.values(response.data.RAW)[0].USD.PRICE);

                            //rewards
                            let reward = this.historyList[i].reward;
                            temp = price * reward;
                            temp2 += temp; 
                            totalUsd = this.round(temp2, 2);

                            if(totalUsd == 0){
                                this.totalRewardUsd = "0.00";
                            }
                            else{
                                this.totalRewardUsd = totalUsd.toLocaleString("us-US");
                            }
                            

                            //locked
                            let locked = this.historyList[i].lockedAmount;
                            temp3 = price * locked;
                            temp4 += temp3; 
                            totalLockedUsd = this.round(temp4, 2);

                            if(totalLockedUsd == 0){
                                this.totalLockedUsd = "0.00";
                            }
                            else{
                                this.totalLockedUsd = totalLockedUsd.toLocaleString("us-US");
                            }
                            

                            

                        }); 
                    }

                }

            },

            async reward(){

                //get staking trans
                let todaySecs = new Date().getTime()/1000;

                const q = query(collection(db, "UserStakings"), where("userId", "==", sessionStorage.getItem("id"))); 
                const querySnapshot = await getDocs(q);

                if(querySnapshot.docs.length > 0){
                    querySnapshot.forEach((doc) => {

                        //check endDate;
                        if(doc.data().type != "Flexible staking" && doc.data().status == "in progress"){
                            if( todaySecs > doc.data().endDate){

                                //staking complete
                                this.updateStaking(doc.data().walletId, doc.data().reward, doc.data().lockedAmount, doc.id);
                                
                                
                            }
                        }

                    })
                }

                this.getHistory();


            },

            async updateStaking(walletId, reward, lockedAmount, docId){

                let balance, newBalance;

                //get balance
                const walletRef = doc(db, "UserWallets", walletId);
                const walletSnap = await getDoc(walletRef);

                if (walletSnap.exists()) {
                    balance = walletSnap.data().balance;
                }

                else{
                    console.log("NO WALLET WITH THIS ID");
                    return;
                }

                //update balance
                newBalance = balance + reward + lockedAmount;
                await updateDoc(walletRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                const stakeRef = doc(db, "UserStakings", docId);
                await updateDoc(stakeRef, {
                    status: "completed"
                });
            },
            

        }

    }

</script>